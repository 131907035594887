<script lang="ts">
export default {
    mounted() {
        this.$router.push('/') // WILL SHOW LOGIN FORM BECAUSE ROUTE WILL BE AT /CONNEXION ON MOUNTED
    },
    render() {
        return null
    },
}
</script>
